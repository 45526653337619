<script>
import taxonomyForm from "@/views/menu/taxonomy/taxonomyForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    taxonomyService: "taxonomyService",
    toastService: "toastService",
  },
})
export default class TaxonomyNew extends mixins(taxonomyForm) {
  formId = "new-taxonomy-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.localizations.forEach((loc)=>{
        if(!loc.name){
          loc.name = "";
        }
      });
      await this.taxonomyService.create(this.model);
      this.$router.push("/taxonomy/list");
      this.toastService.success(this.translations.success.taxonomy_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.taxonomies_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
